import React, { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { CountryDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import AgreementModal from "./AgreementModal";
import DocuSignModal from "./DocuSignModal";
import PowerModal from "./PowerModal";
import PowerOfAttorney from "./PowerOfAttorney";
import { RemoveCircleOutline } from "@mui/icons-material";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import attachmenturl from "../../api/attachmenturl";
import ViewFileModal from "./ViewFileModal";
import PrintNote from "./PrintNote";
import ReactDOMServer from "react-dom/server";
import MessageModal from "../Modals/MessageModal";

const EditClaimForm = () => {
  let params = useParams();
  const claim_id = params.claim_id;

  const [familyMembers, setFamilyMembers] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();
  const powerRef = useRef(null);

  const [backdropopen, setbackdropopen] = useState(false);
  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);

  const [defaultOptions, setDefaultOptions] = useState([]);
  const [defaultAirlineOptions, setDefaultAirlineOptions] = useState([]);

  const [selectedOriginOption, setSelectedOriginOption] = useState(null);
  const [selectedDestOption, setSelectedDestOption] = useState(null);
  const [selectedAirlineOption, setSelectedAirlineOption] = useState(null);
  const [selectedHomeOption, setSelectedHomeOption] = useState(null);
  const [signUrl, setSignUrl] = useState(null);

  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [hourStatus, setHourStatus] = useState(false);
  const [minStatus, setMinStatus] = useState(false);

  const [optionsList, setOptionsList] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);
  const [selectedAdditionalFiles, setSelectedAdditionalFiles] = useState(null);
  const [selectedPassportSubClaim, setSelectedPassportSubClaim] = useState([]);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isPowerOfAttorneyChecked, setIsPowerOfAttorneyChecked] =
    useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [signatureCaptured, setSignatureCaptured] = useState(false);
  const [isTermsLinkClicked, setIsTermsLinkClicked] = useState(false);
  const [isAgreementLinkClicked, setIsAgreementLinkClicked] = useState(false);
  const [isPowerLinkClicked, setIsPowerLinkClicked] = useState(false);
  const [envolpeId, setEnvelopeId] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [isDocuSignModalOpen, setIsDocusignModalOpen] = useState(false);
  const [documentFetched, setDocumentFetched] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isDocumentFetching, setIsDocumentFetching] = useState(false);
  const [triggerIndex, setTriggerIndex] = useState(null);
  const [dataToStore, setDataToStore] = useState(null);
  const [triggerFrom, setTriggerFrom] = useState("original");
  const [email, setEmail] = useState(null);

  const [claimData, setClaimData] = useState({});
  const [previousNote, setPreviousNote] = useState("");
  const [boardingImg, setBoardingImg] = useState();
  const [passportImg, setPassportImg] = useState();
  const [agreement, setAgreement] = useState();
  const [additionalImg, setAdditionalImg] = useState();

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalTitle, setImageModalTitle] = useState("");
  const [imageModalFileUrl, setImageModalFileUrl] = useState("");
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const boardingPass = watch("boarding_pass");
  const passport = watch("passport");
  const agreementFile = watch("agreement");
  const powerOfAttorney = watch("powerPdf");
  const AirlinepowerOfAttorney = watch("airline_poa");

  const [otherDoc, setOtherDoc] = useState([]);

  const isFileUploaded = (fileField) => {
    return fileField?.length > 0;
  };

  useEffect(() => {
    const formData = watch();
    console.log("formdata", formData.boarding_pass);
    const isChanged =
      isFileUploaded(formData.boarding_pass) ||
      isFileUploaded(formData.passport) ||
      isFileUploaded(formData.agreement) ||
      isFileUploaded(formData.powerPdf);
    isFileUploaded(formData.airline_poa);
    console.log("ischanged", isChanged);
    setIsSubmitBtnDisabled(isChanged);
  }, [
    boardingPass,
    passport,
    agreementFile,
    powerOfAttorney,
    AirlinepowerOfAttorney,
  ]);

  const [notes, setNote] = useState();

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const [flightDetails, setFlightDetails] = useState([
    {
      id: null,
      airline: "",
      flight_number: "",
      airport_of_origin: "",
      airport_of_destination: "",
      date_of_travel: "",
      original_departure_time: "",
      original_arrival_time: "",
      original_departure_date: "",
      original_arrival_date: "",
      // original_departure: "",
      // original_arrival: "",
      hub_code: "",

      delayed: false,
      cancelled: false,
      missed: false,
    },
  ]);

  // console.log("148--->",flightDetails);

  const [actualFlightDetails, setActualFlightDetails] = useState([
    {
      id: null,
      actual_airline: "",
      actual_flight_number: "",
      actual_airport_of_origin: "",
      actual_airport_of_destination: "",
      actual_date_of_travel: "",
      actual_departure_time: "",
      actual_arrival_time: "",
      actual_departure_date: "",
      actual_arrival_date: "",

      actual_hub_code: "",
      provided_by_airline: false,
      self_booked: false,
    },
  ]);
  const iframeRef = useRef(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const openMessageModal = () => {
    setIsMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setIsMessageModalOpen(false);
  };

  const handlePrint = () => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;

    // Clear previous content
    doc.body.innerHTML = "";

    // Create a container element for styling
    const container = doc.createElement("div");
    container.style.fontFamily = "Arial, sans-serif";
    container.style.fontSize = "16px";
    container.style.color = "#555";

    //console.log("note", notes);

    // Render StyledNote component as HTML string
    const printNote = (
      <PrintNote
        note={notes}
        claimId={params.claim_id}
        claimData={claimData}
        flightDetails={flightDetails}
        actualFlightDetails={actualFlightDetails}
        otherDoc={otherDoc}
        familyMembers={familyMembers}
      />
    );
    const htmlString = ReactDOMServer.renderToStaticMarkup(printNote);

    // Set the HTML content to the iframe body
    doc.body.innerHTML = htmlString;

    // Focus and print
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  };

  const [delay, setDelay] = useState("00:00");

  const [flightOptions, setFlightOptions] = useState({});

  const [ticketNumberErrors, setTicketNumberErrors] = useState(
    Array(familyMembers.length).fill("")
  );

  const openImageViewModal = (title, fileUrl) => {
    setImageModalTitle(title);
    setImageModalFileUrl(fileUrl);
    setImageModalOpen(true);
  };

  const isImageFile = (url) => {
    // Example function to check if the URL ends with a common image file extension
    return /\.(jpeg|jpg|png|gif)$/i.test(url);
  };

  const handleViewFile = (elem) => {
    if (!elem) {
      return;
    }
    //console.log("Element", attachmenturl + elem);
    const fileUrl = attachmenturl + elem;
    if (!isImageFile(fileUrl)) {
      window.open(fileUrl, "_blank"); // Open the URL in a new tab
    } else {
      openImageViewModal("View File", fileUrl); // Open the file in the modal
    }
  };

  const removeFamilyMember = (index, id) => {
    if (id !== null) {
      removeFamilyMemberData(id);
    }
    const updatedFamilyMember = familyMembers.filter((_, i) => i !== index);
    setFamilyMembers(updatedFamilyMember);
  };

  const removeFamilyMemberData = (id) => {
    setbackdropopen(true);

    axios
      .delete(`${base_url.api1}/tourapp/sub_claim_delete?id=${id}`, {})
      .then(() => {
        setbackdropopen(false);
      })
      .catch(() => {
        setbackdropopen(false);
      });
  };

  const renderViewFileInput = (claim) => {
    if (claim) {
      return (
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleViewFile(claim)}
            type="button"
          >
            View File
          </button>
        </div>
      );
    } else {
      // return (
      //   <div>
      //     <button
      //       className="btn btn-secondary"
      //       disabled={!claim}
      //       style={!claim ? { cursor: "not-allowed" } : {}}
      //       type="button"
      //     >
      //       No File
      //     </button>
      //   </div>
      // );
      return null;
    }
  };

  const handlePassportUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedFamilyMember = [...familyMembers];
    updatedFamilyMember[index]["passport_file"] = file;
    updatedFamilyMember[index]["passport_name"] = `sub_claim_${index + 1}`;
    setFamilyMembers(updatedFamilyMember);
    setIsSubmitBtnDisabled(true);
  };

  const handleAddressChange = (option) => {
    setValue("address", option.label);
    setOptionsList([]);
    setIsOpenList(false);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setIsOpenList(false);
    }, 100);
  };

  // Fetch initial data
  useEffect(() => {
    getClaimDetails();
  }, []);

  const fetchAirlines = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airline_list?search=${inputValue}`
        );
        if (response.data && response.data.airlines) {
          return response.data.airlines.map((airline) => ({
            value: airline.id,
            label: airline.airline_name,
            hub_code: airline.iata_code,
          }));
        }
      } catch (error) {
        console.error("Error fetching airlines:", error);
      }
    }
    return [];
  };

  const handleAdditionalFileChange = (e) => {
    setSelectedAdditionalFiles(e.target.files);
    setIsSubmitBtnDisabled(true);
  };

  const loadOptions = (inputValue, callback) => {
    fetchAirports(inputValue).then((options) => callback(options));
  };

  const fetchAirports = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airport_list?search=${inputValue}`
        );
        if (response.data && response.data.Airports) {
          return response.data.Airports.map((airport) => ({
            value: airport.id,
            label: airport.airport_name,
          }));
        }
      } catch (error) {
        console.error("Error fetching airports:", error);
      }
    }
    return [];
  };

  const loadAirlineOptions = (inputValue, callback) => {
    fetchAirlines(inputValue).then((options) => callback(options));
  };

  const [pdfData, setPdfData] = useState(null);

  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isPowerModalOpen, setIsPowerModalOpen] = useState(false);
  const [powerPdf, setPowerPdf] = useState(null);
  const [airline_poa, setairline_poa] = useState(null);

  const handlePowerPDF = (base64PDF) => {
    const pdfBlob = base64ToBlob(base64PDF, "application/pdf");

    setPowerPdf(pdfBlob);

    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  const addFamilyMember = () => {
    setFamilyMembers([
      ...familyMembers,
      {
        id: null,
        first_name: null,
        last_name: null,
        date_of_birth: null,
        passport_number: null,
        ticket_number: null,
        passport_file: null, // Initialize as null or handle file object
        passport_name: null,
        additional_docu: null,
        additionalDoc_name: null,
      },
    ]);
  };

  const handleAdditionalUpload = (e, index) => {
    const file = e.target.files[0];
    const updatedFamilyMember = [...familyMembers];
    updatedFamilyMember[index]["additional_docu"] = file;
    updatedFamilyMember[index]["additionalDoc_name"] = `sub_doc_claim_${
      index + 1
    }`;
    setFamilyMembers(updatedFamilyMember);
    setIsSubmitBtnDisabled(true);
  };

  const handleTicketNumberChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, "");
    let errorMessage = "";
    if (value.length > 13) {
      return;
    }

    if (!value) {
      errorMessage = "Ticket Number is Required";
    } else if (value.length !== 13) {
      errorMessage = "Ticket Number must be 13 digits";
    }
    const updatedErrors = [...ticketNumberErrors];
    updatedErrors[index] = errorMessage;
    setTicketNumberErrors(updatedErrors);

    setFamilyMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        tickets: value,
      };
      return updatedMembers;
    });
    setIsSubmitBtnDisabled(true);
  };

  const handleInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    setFamilyMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = {
        ...updatedMembers[index],
        [fieldName]: value,
      };
      return updatedMembers;
    });
    setIsSubmitBtnDisabled(true);
  };

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const getClaimDetails = () => {
    setbackdropopen(true);

    axios
      .get(
        `${base_url.api1}/tourapp/compensation_request?id=` + params.claim_id
      )
      .then((response) => {
        let claimDetails = response.data.Compensation;
        //console.log("Sub CLaims-->", claimDetails.sub_claims);
        setClaimData({
          first_name: claimDetails.first_name,
          last_name: claimDetails.last_name,
          address: claimDetails.address,
          passport_number: claimDetails.passport_number,
          nationality: claimDetails.nationality,
          date_of_birth: claimDetails.date_of_birth,
          // airline: claimDetails.airline,
          // flight_number: claimDetails.flight_number,
          // airpotr_of_origin: claimDetails.airpotr_of_origin,
          // airpotr_of_destination: claimDetails.airpotr_of_destination,
          unique_code: claimDetails.unique_code,
          contact_no: claimDetails.contact_no,
          note: claimData.note,
          email_id: claimDetails.email_id,
          status: claimDetails.status,
          receive_any_compensation: claimDetails.receive_any_compensation,
          status: claimDetails.status,
          unique_code: claimDetails.unique_code,
          type_of_claim: claimDetails.type_of_claim, // date_of_travel: claimDetails.date_of_travel,
          tickets: claimDetails.tickets,
          claim_id: claimDetails.contact_no,
          airline_res_no: claimDetails.airline_res_no,
          source_of_claim: claimDetails.source_of_claim,
        });
        setcountry(claimDetails.nationality);
        setCountryValid(false);
        setBoardingImg(claimDetails.boarding_pass);
        setAdditionalImg(claimDetails.additional_exp);
        setPassportImg(claimDetails.passport);
        setAgreement(claimDetails.agreement);
        setPreviousNote(claimDetails.note);
        setNote(claimDetails.note);
        setOtherDoc(claimDetails.other_doc);

        setFamilyMembers(claimDetails.sub_claims);
        setPowerPdf(claimDetails.power_of_attorney);
        setairline_poa(claimDetails.airline_poa);
        if (claimDetails?.flight_details) {
          const transformedFlightDetails = claimDetails.flight_details.map(
            (flight) => ({
              id: flight.id || null, // Use id if provided, else null
              airline: flight.airline || "",
              flight_number: flight.flight_number || "",
              airport_of_origin: flight.airport_of_origin || "",
              airport_of_destination: flight.airport_of_destination || "",
              date_of_travel: flight.date_of_travel || "",
              original_departure_time: flight.scheduled_departure
                ? moment.parseZone(flight.scheduled_departure).format("HH:mm")
                : "",
              original_arrival_time: flight.scheduled_arrival
                ? moment.parseZone(flight.scheduled_arrival).format("HH:mm")
                : "",
              original_departure_date: flight.scheduled_departure
                ? moment
                    .parseZone(flight.scheduled_departure)
                    .format("YYYY-MM-DD")
                : "",
              original_arrival_date: flight.scheduled_arrival
                ? moment
                    .parseZone(flight.scheduled_arrival)
                    .format("YYYY-MM-DD")
                : "",
              delayed: flight.delayed || false,
              cancelled: flight.cancelled || false,
              missed: flight.missed || false,
            })
          );

          setFlightDetails(transformedFlightDetails);
        }
        if (claimDetails?.actual_flight_details) {
          const transformedActualFlightDetails =
            claimDetails.actual_flight_details.map((flight) => ({
              id: flight.id || null, // Use id if provided, else null
              actual_airline: flight.actual_airline || "",
              actual_flight_number: flight.actual_flight_number || "",
              actual_airport_of_origin: flight.actual_airport_of_origin || "",
              actual_airport_of_destination:
                flight.actual_airport_of_destination || "",
              actual_date_of_travel: flight.actual_date_of_travel || "",
              actual_departure_time: flight.actual_departure
                ? moment.parseZone(flight.actual_departure).format("HH:mm")
                : "",
              actual_arrival_time: flight.actual_arrival
                ? moment.parseZone(flight.actual_arrival).format("HH:mm")
                : "",
              actual_departure_date: flight.actual_departure
                ? moment.parseZone(flight.actual_departure).format("YYYY-MM-DD")
                : "",
              actual_arrival_date: flight.actual_arrival
                ? moment.parseZone(flight.actual_arrival).format("YYYY-MM-DD")
                : "",
              provided_by_airline: flight.provided_by_airline,
              self_booked: flight.self_booked,
            }));

          setActualFlightDetails(transformedActualFlightDetails);
        }
        // if (claimDetails.notification) {
        //   UpdateNotificationCount();
        // }
        setDelay(claimDetails?.delay);

        setbackdropopen(false);
      })
      .catch(() => {
        setbackdropopen(false);
      });
  };

  const onSubmit = (data) => {
    // if (!country1) {
    //   setCountryValid(true);
    //   return;
    // }

    //console.log("Sub Claim", subClaims);
    //console.log("data", data);
    //console.log("claimdata", claimData);
    const formData = new FormData();
    // const formatedNote = handleSaveNote();

    // Collecting all form data into a single object

    const flighDetailsData = flightDetails.map((flight) => {
      const newData = {
        airline: flight.airline,
        flight_number: flight.flight_number,
        airport_of_origin: flight.airport_of_origin,
        airport_of_destination: flight.airport_of_destination,
        scheduled_arrival: `${flight.original_arrival_date}T${flight.original_arrival_time}`,
        scheduled_departure: `${flight.date_of_travel}T${flight.original_departure_time}`,
        date_of_travel: flight.date_of_travel,
        delayed: flight.delayed,
        cancelled: flight.cancelled,
        missed: flight.missed,
      };
      if (flight.id !== null) {
        newData.id = flight.id;
      }
      return newData;
    });

    const actualFlighDetailsData = actualFlightDetails.map((flight) => {
      const newData = {
        actual_departure: `${flight.actual_date_of_travel}T${flight.actual_departure_time}`,
        actual_arrival: `${flight.actual_arrival_date}T${flight.actual_arrival_time}`,
        actual_airport_of_origin: flight.actual_airport_of_origin,
        actual_date_of_travel: flight.actual_date_of_travel,
        actual_airport_of_destination: flight.actual_airport_of_destination,
        actual_airline: flight.actual_airline,
        actual_flight_number: flight.actual_flight_number,
        provided_by_airline: flight.provided_by_airline,
        self_booked: flight.self_booked,
      };
      if (flight.id !== null) {
        newData.id = flight.id;
      }
      return newData;
    });

    const subClaimsData = familyMembers.map((claim) => {
      const subClaimData = {
        first_name: claim.first_name,
        last_name: claim.last_name,
        passport_number: claim.passport_number,
        tickets: claim.tickets,
        date_of_birth: claim.date_of_birth,
      };

      if (claim.id !== null) {
        subClaimData.id = claim.id;
      }
      if (claim.passport_file) {
        subClaimData.passport = claim.passport_name;
        formData.append(claim.passport_name, claim.passport_file);
      }
      if (claim.additional_docu) {
        subClaimData.additional_doc = claim.additionalDoc_name;
        formData.append(claim.additionalDoc_name, claim.additional_docu);
      }

      return subClaimData;
    });

    //console.log("Sub Claim", subClaimsData);

    const formObject = {
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      passport_number: data.passport_type_number,
      nationality: country1,
      date_of_birth: data.date_of_birth,

      status: data.status,

      contact_no: data.contact_no,
      email_id: data.email_id,
      receive_any_compensation: data.receive_any_compensation,
      date_of_travel: data.date_of_travel,
      tickets: data.tickets,
      sub_claims: subClaimsData,
      flight_details: flighDetailsData,
      actual_flight_details:
        claimData.type_of_claim === "flight delay / flight cancellation"
          ? actualFlighDetailsData
          : null,
      delay: delay,
      airline_res_no: data.airline_res_no,
    };

    // Convert the form object to JSON
    const jsonFormObject = JSON.stringify(formObject);
    formData.append("boarding_pass", data.boarding_pass[0] || null);
    formData.append("passport", data.passport[0] || null);
    formData.append("agreement", data.agreement[0] || null);
    formData.append("power_of_attorney", data.powerPdf[0] || null);
    formData.append("airline_poa", data.airline_poa[0] || null);
    // Append the JSON object to the FormData
    formData.append("data", jsonFormObject);

    if (selectedAdditionalFiles) {
      for (let i = 0; i < selectedAdditionalFiles.length; i++) {
        formData.append("document", selectedAdditionalFiles[i]);
      }
    }

    setbackdropopen(true);
    postClaimRequest(formData);
  };

  const postClaimRequest = (data) => {
    axios
      .put(`${base_url.api1}/tourapp/compensation_request?id=${claim_id}`, data)
      .then(
        () => {
          setbackdropopen(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Changes uploaded successfully.`,
          }).then(() => {
            window.location.reload();
          });
        },
        () => {
          setbackdropopen(false);
        }
      );
  };

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        {/* <div style={{ position: "relative", height: "25vh" }}>
          <img
            src={bannerbackground}
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
          <div className="overlaybg">
            <div style={{ postion: "absolute" }}>
              <h1
                style={{
                  width: "100%",
                  marginTop: "200px",
                  fontSize: "3.5rem",
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: " Caveat,cursive",
                }}
              >
                {tourTitle ? tourTitle : null}
              </h1>
            </div>
          </div>
        </div> */}

        <div className="container">
          <div
            class="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul class="breadcrums">
              <Link to="/" class="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="/claimstatus" class="b-link">
                Claim Status
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" class="b-link active">
                Edit Claim
              </Link>
            </ul>
          </div>
          <div className="tabs">
            <div className={"tab  active-tab"}>{claimData.type_of_claim}</div>
          </div>
          <Row className="px-0">
            <Col lg={12} className="carosol_img" style={{ marginTop: "-22px" }}>
              <form
                id="myForm"
                onSubmit={handleSubmit(onSubmit)}
                className="login_container"
              >
                <p className="tour_head_text_3">Personal Details</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control extra-light"
                        value={claimData.first_name}
                        placeholder="First Name"
                        type="text"
                        disabled
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.first_name && (
                      <p className="errormessage">First Name is Required</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control extra-light"
                        value={claimData.last_name}
                        placeholder="Last Name"
                        type="text"
                        disabled
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.last_name && (
                      <p className="errormessage">Last Name is Required</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label>
                      Date Of Birth<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="date"
                        className="form-control extra-light"
                        value={claimData.date_of_birth}
                        placeholder="DOB"
                        disabled
                      />
                      <i className="fa fa-calendar designi" />
                    </div>
                    {errors.date_of_birth && (
                      <p className="errormessage">Date Of Birth is Required</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Home Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={claimData.address}
                        // onChange={handleAddressInputChange}
                        placeholder="Enter Home Address"
                        className="search-input form-control extra-light"
                        type="text"
                        autoComplete="off"
                        onBlur={handleInputBlur}
                        disabled
                      />
                      <i
                        className="fa fa-map-marker home_icon"
                        style={{ position: "absolute", left: 15, top: 10 }}
                      />
                      {isOpenList && optionsList.length > 0 && (
                        <ul className="search-results">
                          {optionsList.map((option, index) => (
                            <li
                              key={index}
                              onClick={() => handleAddressChange(option)}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                      {errors.address && (
                        <p className="errormessage">{errors.address.message}</p>
                      )}
                    </div>
                  </div>
                  <Col md={4}>
                    <label>
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={claimData.contact_no}
                        className="form-control extra-light"
                        placeholder="Mobile Number"
                        type="text"
                        // onChange={handleMobileInputChange}
                        disabled
                      />
                      <i className="fa fa-phone designi" />
                    </div>
                    {errors.contact && (
                      <p className="errormessage">{errors.contact.message}</p>
                    )}
                  </Col>

                  <div className="col-md-4">
                    <label>
                      Email Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={claimData.email_id}
                        className="form-control extra-light"
                        placeholder="Email Address"
                        type="email"
                        disabled
                      />
                      <i className="fa fa-envelope designi" />
                    </div>
                    {errors.email && (
                      <p className="errormessage">{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>
                      Passport Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control extra-light"
                        value={claimData.passport_number}
                        placeholder="Passport number"
                        type="text"
                        disabled
                      />
                      <i className="fa fa-id-card designi" />
                    </div>
                    {errors.passport_type_number && (
                      <p className="errormessage">
                        Passport Number is Required
                      </p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Nationality<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <CountryDropdown
                        className="countrydropdown2 form-control underline-input"
                        id="tcountry"
                        defaultOptionLabel="- Select Country -"
                        value={country1}
                        // onChange={(val) => {
                        //   setcountry(val);
                        //   setCountryValid(false);
                        // }}
                        disabled
                      />
                      <i className="fas fa-globe designi" />
                    </div>
                    {countryValid ? (
                      <p className="errormessage">Country is Required</p>
                    ) : null}
                  </div>
                  <Col md={3}>
                    <label>
                      13 digit ticket Number
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={claimData.tickets}
                        className="form-control extra-light"
                        placeholder="Ticket Number"
                        type="text"
                        // onChange={handleTicketInputChange}
                        disabled
                      />
                      <i className="fa fa-ticket designi" />
                    </div>
                    {errors.tickets && (
                      <p className="errormessage">{errors.tickets.message}</p>
                    )}
                  </Col>
                  <Col md={3}>
                    <label>
                      Airline Reservation Number
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control extra-light"
                        value={claimData.airline_res_no}
                        placeholder="Airline Reservation Number"
                        type="text"
                        disabled
                      />
                      <i className="fa fa-ticket designi" />
                    </div>
                    {errors.airline_res_no && (
                      <p className="errormessage">
                        Airline Reservation Number is Required
                      </p>
                    )}
                  </Col>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="d-flex align-items-center">
                        <label>Boarding Pass</label>
                        {boardingImg && (
                          <button
                            className={`btn ml-3 ${
                              boardingImg ? "btn-primary" : "btn-secondary"
                            }`}
                            onClick={() => handleViewFile(boardingImg)}
                            disabled={!boardingImg}
                            style={{
                              width: "35%",
                              marginRight: "17px",
                              marginLeft: "10px",
                              marginBottom: "10px",
                              ...(boardingImg ? {} : { cursor: "not-allowed" }),
                            }}
                            type="button"
                          >
                            {boardingImg ? "View File" : "No File"}
                          </button>
                        )}
                      </div>

                      <div style={{ position: "relative" }} className="d-flex">
                        <div>
                          <input
                            type="file"
                            className="form-control"
                            {...register("boarding_pass", {
                              required: false,
                            })}
                            // style={{ width: "34%" }}
                          />
                          <i className="fa fa-file-image-o designi" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="d-flex align-items-center">
                        <label>Passport</label>
                        {passportImg && (
                          <button
                            className={`btn ml-3 ${
                              passportImg ? "btn-primary" : "btn-secondary"
                            }`}
                            onClick={() => handleViewFile(passportImg)}
                            disabled={!passportImg}
                            style={{
                              width: "35%",
                              marginRight: "17px",
                              marginLeft: "10px",
                              marginBottom: "10px",
                              ...(passportImg ? {} : { cursor: "not-allowed" }),
                            }}
                            type="button"
                          >
                            {passportImg ? "View File" : "No File"}
                          </button>
                        )}
                      </div>
                      <div style={{ position: "relative" }}>
                        <input
                          type="file"
                          className="form-control"
                          {...register("passport", {
                            required: false,
                          })}
                        />
                        <i className="fa fa-file-image-o designi" />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label>Additional Claims Documents</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="file"
                          multiple
                          onChange={handleAdditionalFileChange}
                          className="form-control"
                        />
                        <i className="fa fa-file-image-o designi" />
                      </div>
                    </div>
                    {otherDoc?.map((doc, index) => (
                      <Col md={4} key={doc.id} style={{ marginTop: "25px" }}>
                        <div className="d-flex align-items-center">
                          <label>Additional Document {index + 1}:</label>
                          <button
                            className={`btn ml-3 ${
                              doc.document ? "btn-primary" : "btn-secondary"
                            }`}
                            onClick={() => handleViewFile(doc.document)}
                            disabled={!doc.document}
                            style={{
                              width: "35%",
                              marginRight: "17px",
                              marginLeft: "10px",
                              marginBottom: "10px",
                              ...(doc.document
                                ? {}
                                : { cursor: "not-allowed" }),
                            }}
                            type="button"
                          >
                            {doc.document ? "View File" : "No File"}
                          </button>
                        </div>
                      </Col>
                    ))}
                  </div>
                </div>

                <div className="row">
                  {familyMembers.length > 0 &&
                    familyMembers.map((member, index) => (
                      <div className="col-12 " key={index}>
                        <h4>Traveler {index + 2}</h4>{" "}
                        {/* Display Traveler 1, Traveler 2, etc. */}
                        {/* First Name, Last Name, and Date Of Birth Fields */}
                        <div className="row">
                          <div className="col-md-3">
                            <label>
                              First Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="First Name"
                                type="text"
                                value={member.first_name}
                                onChange={(e) =>
                                  handleInputChange(e, index, "first_name")
                                }
                                required
                              />
                              <i className="fa fa-user-o designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>
                              Last Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="Last Name"
                                type="text"
                                value={member.last_name}
                                onChange={(e) =>
                                  handleInputChange(e, index, "last_name")
                                }
                                required
                              />
                              <i className="fa fa-user-o designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>
                              Date Of Birth{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                type="date"
                                value={member.date_of_birth}
                                onChange={(e) =>
                                  handleInputChange(e, index, "date_of_birth")
                                }
                                required
                              />
                              <i className="fa fa-calendar designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label>Passport Number</label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="Passport Number"
                                type="text"
                                value={member.passport_number}
                                onChange={(e) =>
                                  handleInputChange(e, index, "passport_number")
                                }
                                required
                              />
                              <i className="fa fa-id-card designi" />
                            </div>
                          </div>
                        </div>
                        {/* Passport Number, Ticket Number Fields */}
                        <div className="row mt-3">
                          <div className="col-md-3">
                            <label>
                              Ticket Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control"
                                placeholder="Ticket Number"
                                type="text"
                                value={member.tickets}
                                onChange={(e) =>
                                  handleTicketNumberChange(e, index)
                                }
                                required
                              />
                              {ticketNumberErrors[index] && (
                                <p className="errormessage">
                                  {ticketNumberErrors[index]}
                                </p>
                              )}
                              <i className="fa fa-ticket designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="d-flex gap-3 align-items-center">
                              <label>Passport:</label>{" "}
                              {renderViewFileInput(member.passport)}
                            </div>
                            {/* <label>Passport:</label> */}
                            <div style={{ position: "relative" }}>
                              <input
                                type="file"
                                className="form-control mt-2"
                                onChange={(e) => handlePassportUpload(e, index)}
                              />
                              <i className="fa fa-file-image-o designi" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="d-flex gap-3 align-items-center ">
                              <label>Additional Document:</label>
                              {renderViewFileInput(member.additional_doc)}
                            </div>
                            {/* <label>Additional Document:</label> */}
                            <div style={{ position: "relative" }}>
                              <input
                                type="file"
                                className="form-control mt-2"
                                onChange={(e) =>
                                  handleAdditionalUpload(e, index)
                                }
                              />
                              <i className="fa fa-file-image-o designi" />
                            </div>
                          </div>
                        </div>
                        {/* Additional Document Field with Remove Button */}
                        <div className="row mt-3">
                          {/* <div className="col-md-2">
                            <Button
                              color="danger"
                              size="sm" // Smaller button size
                              onClick={() => removeFamilyMember(index)}
                              style={{ marginTop: "30px" }}
                            >
                              Remove
                            </Button>
                          </div> */}
                        </div>
                      </div>
                    ))}
                </div>
                <Button
                  color="primary"
                  onClick={addFamilyMember}
                  className="mt-3 mb-3"
                  style={{ width: "180px" }}
                >
                  Add Travelers
                </Button>
                <p className="tour_head_text_3">
                  {claimData.type_of_claim ===
                  "flight delay / flight cancellation"
                    ? "Flight Details(Originally Booked)"
                    : "Flight Details"}
                </p>
                <hr style={{ width: "20%" }} />
                {flightDetails.length > 0 &&
                  flightDetails.map((flight, index) => (
                    <div className="row" key={index}>
                      {claimData.type_of_claim ===
                        "flight delay / flight cancellation" && (
                        <h3>Flight {index + 1}</h3>
                      )}
                      <div className="col-md-4">
                        <label>
                          Airline<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name={`Airline_${index}`}
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                              <AsyncSelect
                                {...field}
                                cacheOptions
                                defaultOptions={defaultAirlineOptions}
                                loadOptions={loadAirlineOptions}
                                value={
                                  flight.airline
                                    ? {
                                        label: flight.airline,
                                        value: flight.airline,
                                      }
                                    : null
                                }
                                placeholder="Select Airline"
                                // onChange={(selectedOption) => {
                                //   setFlightDetails((prevState) => {
                                //     const updatedFlightDetails = [...prevState];
                                //     updatedFlightDetails[index].hub_code =
                                //       selectedOption?.hub_code || "";

                                //     updatedFlightDetails[index].airline =
                                //       selectedOption?.label || "";
                                //     return updatedFlightDetails;
                                //   });
                                //   setTriggerIndex(index);
                                //   setTriggerFrom("original");
                                //   field.onChange(selectedOption?.label || "");
                                // }}
                                isDisabled={true}
                              />
                            )}
                          />
                          {errors[`Airline_${index}`] && (
                            <p className="errormessage">Airline is Required</p>
                          )}
                        </div>
                      </div>

                      {/* Flight Number */}
                      <div className="col-md-4">
                        <label>
                          Flight Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            className="form-control extra-light"
                            placeholder="Flight Number"
                            type="text"
                            value={flight.flight_number}
                            // onChange={(e) => {
                            //   const updatedFlightDetails = [...flightDetails];
                            //   updatedFlightDetails[index].flight_number =
                            //     e.target.value;
                            //   setFlightDetails(updatedFlightDetails);
                            //   setTriggerIndex(index);
                            //   setTriggerFrom("original");
                            // }}
                            disabled
                          />
                          <i className="fa fa-ticket designi" />
                        </div>
                        {errors[`flight_number_${index}`] && (
                          <p className="errormessage">
                            Flight Number is Required
                          </p>
                        )}
                      </div>

                      {/* Date Of Travel */}
                      <div className="col-md-4">
                        <label>
                          Travel Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="date"
                            className="form-control extra-light"
                            value={flight.date_of_travel}
                            // onChange={(e) => {
                            //   const updatedFlightDetails = [...flightDetails];
                            //   updatedFlightDetails[index].date_of_travel =
                            //     e.target.value;

                            //   setFlightDetails(updatedFlightDetails);
                            //   setTriggerIndex(index);
                            //   setTriggerFrom("original");
                            // }}
                            disabled
                          />
                          <i className="fa fa-calendar designi" />
                        </div>
                        {errors[`date_of_travel_${index}`] && (
                          <p className="errormessage">
                            Travel Date is Required
                          </p>
                        )}
                      </div>
                      {/* Airport of Origin */}
                      <div className="col-md-3">
                        <label>
                          Airport of Origin
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {flightOptions[index] &&
                        flightOptions[index].length > 0 ? (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_origin_${index}`}
                              control={control}
                              rules={{
                                required: false,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  defaultOptions={flightOptions[index].map(
                                    (option) => ({
                                      value: option.departure.airport,
                                      arrival: option.arrival.airport,
                                      departure: option.departure.airport,
                                      label: `${option.departure.airport} - ${option.arrival.airport}`,
                                    })
                                  )}
                                  value={
                                    flight.airport_of_origin
                                      ? {
                                          label: flight.airport_of_origin,
                                          value: flight.airport_of_origin,
                                        }
                                      : null
                                  }
                                  placeholder="Select Origin Airport"
                                  // onChange={(selectedOption) => {
                                  //   console.log(
                                  //     "selectedOptions 1360",
                                  //     selectedOption
                                  //   );
                                  //   setTriggerIndex(null);
                                  //   const updatedFlightDetails = [
                                  //     ...flightDetails,
                                  //   ];
                                  //   updatedFlightDetails[
                                  //     index
                                  //   ].airport_of_origin =
                                  //     selectedOption?.departure || "";
                                  //   updatedFlightDetails[
                                  //     index
                                  //   ].airport_of_destination =
                                  //     selectedOption?.arrival || "";
                                  //   setFlightDetails(updatedFlightDetails);
                                  //   field.onChange(selectedOption?.label || "");
                                  // }}
                                  isDisabled={true}
                                />
                              )}
                            />
                            {errors[`airport_of_origin_${index}`] && (
                              <p className="errormessage">
                                Origin Airport is Required
                              </p>
                            )}
                          </div>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_origin_${index}`}
                              control={control}
                              defaultValue={flight.airport_of_origin}
                              rules={{
                                required: false,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  defaultOptions={defaultOptions}
                                  value={
                                    flight.airport_of_origin
                                      ? {
                                          label: flight.airport_of_origin,
                                          value: flight.airport_of_origin,
                                        }
                                      : null
                                  }
                                  placeholder="Select Origin Airport"
                                  // onChange={(selectedOption) => {
                                  //   setTriggerIndex(null);
                                  //   const updatedFlightDetails = [
                                  //     ...flightDetails,
                                  //   ];
                                  //   updatedFlightDetails[
                                  //     index
                                  //   ].airport_of_origin =
                                  //     selectedOption?.label || "";
                                  //   setFlightDetails(updatedFlightDetails);
                                  //   field.onChange(selectedOption?.label || "");
                                  // }}
                                  isDisabled={true}
                                />
                              )}
                            />
                            {errors[`airport_of_origin_${index}`] && (
                              <p className="errormessage">
                                Origin Airport is Required
                              </p>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-2">
                        <label>
                          Departure Time
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="time"
                            className="form-control extra-light"
                            value={flight.original_departure_time}
                            // onChange={(e) => {
                            //   const updatedFlightDetails = [...flightDetails];
                            //   const updatedTime = e.target.value;
                            //   updatedFlightDetails[
                            //     index
                            //   ].original_departure_time = updatedTime;

                            //   setFlightDetails(updatedFlightDetails);
                            //   setTriggerIndex(null);
                            // }}
                            disabled
                          />
                          <i className="fa fa-clock-o designi" />
                        </div>
                        {errors[`original_departure_time_${index}`] && (
                          <p className="errormessage">
                            Departure Time is Required
                          </p>
                        )}
                      </div>

                      {/* Airport of Destination */}
                      <div className="col-md-3">
                        <label>
                          Airport of Destination
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {flightOptions[index] &&
                        flightOptions[index].length > 0 ? (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_destination_${index}`}
                              control={control}
                              rules={{
                                required: false,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  defaultOptions={flightOptions[index].map(
                                    (option) => ({
                                      value: option.arrival.airport,
                                      arrival: option.arrival.airport,
                                      departure: option.departure.airport,
                                      label: `${option.departure.airport} - ${option.arrival.airport}`,
                                    })
                                  )}
                                  value={
                                    flight.airport_of_destination
                                      ? {
                                          label: flight.airport_of_destination,
                                          value: flight.airport_of_destination,
                                        }
                                      : null
                                  }
                                  placeholder="Select Origin Airport"
                                  // onChange={(selectedOption) => {
                                  //   console.log(
                                  //     "selectedOptions 1466",
                                  //     selectedOption
                                  //   );
                                  //   setTriggerIndex(null);
                                  //   const updatedFlightDetails = [
                                  //     ...flightDetails,
                                  //   ];
                                  //   updatedFlightDetails[
                                  //     index
                                  //   ].airport_of_origin =
                                  //     selectedOption?.departure || "";
                                  //   updatedFlightDetails[
                                  //     index
                                  //   ].airport_of_destination =
                                  //     selectedOption?.arrival || "";
                                  //   setFlightDetails(updatedFlightDetails);
                                  //   field.onChange(selectedOption?.label || "");
                                  // }}
                                  isDisabled={true}
                                />
                              )}
                            />
                            {errors[`airport_of_destination_${index}`] && (
                              <p className="errormessage">
                                Destination Airport is Required
                              </p>
                            )}
                          </div>
                        ) : (
                          <div style={{ position: "relative" }}>
                            <Controller
                              name={`airport_of_destination_${index}`}
                              control={control}
                              defaultValue={flight.airport_of_destination}
                              rules={{
                                required: false,
                              }}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  loadOptions={loadOptions}
                                  defaultOptions={defaultOptions}
                                  value={
                                    flight.airport_of_destination
                                      ? {
                                          label: flight.airport_of_destination,
                                          value: flight.airport_of_destination,
                                        }
                                      : null
                                  }
                                  placeholder="Select Destination Airport"
                                  // onChange={(selectedOption) => {
                                  //   setTriggerIndex(null);
                                  //   const updatedFlightDetails = [
                                  //     ...flightDetails,
                                  //   ];
                                  //   updatedFlightDetails[
                                  //     index
                                  //   ].airport_of_destination =
                                  //     selectedOption?.label || "";
                                  //   setFlightDetails(updatedFlightDetails);
                                  //   field.onChange(selectedOption?.label || "");
                                  // }}
                                  isDisabled={true}
                                />
                              )}
                            />
                            {errors[`airport_of_destination_${index}`] && (
                              <p className="errormessage">
                                Destination Airport is Required
                              </p>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-2">
                        <label>
                          Arrival Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="date"
                            className="form-control extra-light"
                            name="original_arrival_date"
                            value={flight.original_arrival_date}
                            // onChange={(e) => {
                            //   const updatedFlightDetails = [...flightDetails];
                            //   const updatedDate = e.target.value;
                            //   updatedFlightDetails[
                            //     index
                            //   ].original_arrival_date = updatedDate;

                            //   setFlightDetails(updatedFlightDetails);
                            //   setTriggerIndex(null);
                            // }}
                            disabled
                          />
                          <i className="fa fa-calendar designi" />
                        </div>
                        {errors[`original_arrival_date_${index}`] && (
                          <p className="errormessage">
                            Arrival Date is Required
                          </p>
                        )}
                      </div>

                      <div className="col-md-2">
                        <label>
                          Arrival Time
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <div style={{ display: "flex", gap: "20px" }}>
                            <input
                              type="time"
                              className="form-control extra-light"
                              name="original_arrival_time"
                              value={flight.original_arrival_time}
                              // onChange={(e) => {
                              //   const updatedFlightDetails = [...flightDetails];
                              //   const updatedTime = e.target.value;
                              //   updatedFlightDetails[
                              //     index
                              //   ].original_arrival_time = updatedTime;
                              //   setFlightDetails(updatedFlightDetails);
                              //   setTriggerIndex(null);
                              // }}
                              disabled
                            />
                            <i className="fa fa-clock-o designi" />
                            {/* {flightDetails.length > 1 && (
                              <div
                                style={{
                                  backgroundColor: "none",
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft: "2px",
                                }}
                                onClick={() => removeFlightDetails(index)}
                              >
                                <RemoveCircleOutline />
                              </div>
                            )} */}
                          </div>
                        </div>
                        {errors[`original_arrival_time_${index}`] && (
                          <p className="errormessage">
                            Arrival Time is Required
                          </p>
                        )}
                      </div>

                      {/* Delayed and Cancelled checkboxes in one column with spacing */}
                      {claimData.type_of_claim ===
                        "flight delay / flight cancellation" && (
                        <div className="col-md-6 ">
                          <div className="d-flex flex-wrap gap-3">
                            {/* Delayed Checkbox */}
                            <div className="form-check d-flex align-items-center">
                              <label className="form-check-label me-5 mb-0">
                                Delayed
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={flight.delayed}
                                // onChange={() =>
                                //   setFlightDetails((prevState) => {
                                //     const updatedFlightDetails = [...prevState];
                                //     updatedFlightDetails[index] = {
                                //       ...updatedFlightDetails[index],
                                //       delayed: !flight.delayed,
                                //       cancelled: false, // Explicitly uncheck Cancelled
                                //       missed: false, // Explicitly uncheck Missed
                                //     };
                                //     return updatedFlightDetails;
                                //   })
                                // }
                                disabled
                              />
                            </div>

                            {/* Cancelled Checkbox */}
                            <div className="form-check d-flex align-items-center">
                              <label className="form-check-label me-5 mb-0">
                                Cancelled
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={flight.cancelled}
                                // onChange={() =>
                                //   setFlightDetails((prevState) => {
                                //     const updatedFlightDetails = [...prevState];
                                //     updatedFlightDetails[index] = {
                                //       ...updatedFlightDetails[index],
                                //       cancelled: !flight.cancelled,
                                //       delayed: false, // Explicitly uncheck Delayed
                                //       missed: false, // Explicitly uncheck Missed
                                //     };
                                //     return updatedFlightDetails;
                                //   })
                                // }
                                disabled
                              />
                            </div>

                            {/* Missed Checkbox */}
                            <div className="form-check d-flex align-items-center">
                              <label className="form-check-label me-5 mb-0">
                                Missed
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={flight.missed}
                                // onChange={() =>
                                //   setFlightDetails((prevState) => {
                                //     const updatedFlightDetails = [...prevState];
                                //     updatedFlightDetails[index] = {
                                //       ...updatedFlightDetails[index],
                                //       missed: !flight.missed,
                                //       delayed: false, // Explicitly uncheck Delayed
                                //       cancelled: false, // Explicitly uncheck Cancelled
                                //     };
                                //     return updatedFlightDetails;
                                //   })
                                // }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                {/* <Button
                  color="primary"
                  onClick={addFlightDetails}
                  className="mt-3 mb-3"
                  style={{ width: "200px" }}
                >
                  Additional Flight
                </Button> */}
                {claimData.type_of_claim ===
                  "flight delay / flight cancellation" && (
                  <>
                    <p className="tour_head_text_3">
                      Flight Details(Actual Flight)
                    </p>
                    <hr style={{ width: "20%" }} />
                    {actualFlightDetails.length > 0 &&
                      actualFlightDetails.map((flight, index) => (
                        <div className="row">
                          <h3>Flight {index + 1}</h3>

                          <div className="col-md-4">
                            <label>
                              Airline<span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <Controller
                                name={`Actual_Airline_${index}`}
                                control={control}
                                rules={{
                                  required: false,
                                }} // Mandatory for the first flight
                                render={({ field }) => (
                                  <AsyncSelect
                                    {...field}
                                    cacheOptions
                                    defaultOptions={defaultAirlineOptions}
                                    loadOptions={loadAirlineOptions}
                                    value={
                                      flight.actual_airline
                                        ? {
                                            label: flight.actual_airline,
                                            value: flight.actual_airline,
                                          }
                                        : null
                                    }
                                    placeholder="Select Airline"
                                    // onChange={(selectedOption) => {
                                    //   setActualFlightDetails((prevState) => {
                                    //     const updatedFlightDetails = [
                                    //       ...prevState,
                                    //     ];
                                    //     updatedFlightDetails[
                                    //       index
                                    //     ].actual_hub_code =
                                    //       selectedOption?.hub_code || "";

                                    //     updatedFlightDetails[
                                    //       index
                                    //     ].actual_airline =
                                    //       selectedOption?.label || "";
                                    //     return updatedFlightDetails;
                                    //   });
                                    //   setTriggerIndex(index);
                                    //   setTriggerFrom("actual");
                                    //   field.onChange(
                                    //     selectedOption?.label || ""
                                    //   );
                                    // }}
                                    isDisabled={true}
                                  />
                                )}
                              />
                              {errors[`Actual_Airline_${index}`] && (
                                <p className="errormessage">
                                  Airline is Required
                                </p>
                              )}
                            </div>
                          </div>

                          {/* Flight Number */}
                          <div className="col-md-4">
                            <label>
                              Flight Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                className="form-control extra-light"
                                placeholder="Flight Number"
                                type="text"
                                value={flight.actual_flight_number}
                                // onChange={(e) => {
                                //   const updatedFlightDetails = [
                                //     ...actualFlightDetails,
                                //   ];
                                //   updatedFlightDetails[
                                //     index
                                //   ].actual_flight_number = e.target.value;
                                //   setActualFlightDetails(updatedFlightDetails);
                                //   setTriggerIndex(index);
                                //   setTriggerFrom("actual");
                                // }}
                                disabled
                              />
                              <i className="fa fa-ticket designi" />
                            </div>
                            {errors[`flight_number_${index}`] && (
                              <p className="errormessage">
                                Flight Number is Required
                              </p>
                            )}
                          </div>

                          {/* Date Of Travel */}
                          <div className="col-md-4">
                            <label>
                              Travel Date<span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="date"
                                className="form-control extra-light"
                                value={flight.actual_date_of_travel}
                                // onChange={(e) => {
                                //   const updatedFlightDetails = [
                                //     ...actualFlightDetails,
                                //   ];
                                //   updatedFlightDetails[
                                //     index
                                //   ].actual_date_of_travel = e.target.value;

                                //   setActualFlightDetails(updatedFlightDetails);
                                //   setTriggerIndex(index);
                                //   setTriggerFrom("actual");
                                // }}
                                disabled
                              />
                              <i className="fa fa-calendar designi" />
                            </div>
                            {errors[`date_of_travel_${index}`] && (
                              <p className="errormessage">
                                Travel Date is Required
                              </p>
                            )}
                          </div>
                          {/* Airport of Origin */}
                          <div className="col-md-3">
                            <label>
                              Airport of Origin
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {flightOptions[index] &&
                            flightOptions[index].length > 0 ? (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_origin_${index}`}
                                  control={control}
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      defaultOptions={flightOptions[index].map(
                                        (option) => ({
                                          value: option.departure.airport,
                                          arrival: option.arrival.airport,
                                          departure: option.departure.airport,
                                          label: `${option.departure.airport} - ${option.arrival.airport}`,
                                        })
                                      )}
                                      value={
                                        flight.actual_airport_of_origin
                                          ? {
                                              label:
                                                flight.actual_airport_of_origin,
                                              value:
                                                flight.actual_airport_of_origin,
                                            }
                                          : null
                                      }
                                      placeholder="Select Origin Airport"
                                      // onChange={(selectedOption) => {
                                      //   console.log(
                                      //     "selectedOptions 1360",
                                      //     selectedOption
                                      //   );
                                      //   setTriggerIndex(null);
                                      //   const updatedFlightDetails = [
                                      //     ...actualFlightDetails,
                                      //   ];
                                      //   updatedFlightDetails[
                                      //     index
                                      //   ].actual_airport_of_origin =
                                      //     selectedOption?.label || "";

                                      //   setActualFlightDetails(
                                      //     updatedFlightDetails
                                      //   );
                                      //   field.onChange(
                                      //     selectedOption?.label || ""
                                      //   );
                                      // }}
                                      isDisabled={true}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_origin_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Origin Airport is Required
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_origin_${index}`}
                                  control={control}
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      loadOptions={loadOptions}
                                      defaultOptions={defaultOptions}
                                      value={
                                        flight.actual_airport_of_origin
                                          ? {
                                              label:
                                                flight.actual_airport_of_origin,
                                              value:
                                                flight.actual_airport_of_origin,
                                            }
                                          : null
                                      }
                                      placeholder="Select Origin Airport"
                                      // onChange={(selectedOption) => {
                                      //   setTriggerIndex(null);
                                      //   const updatedFlightDetails = [
                                      //     ...actualFlightDetails,
                                      //   ];
                                      //   updatedFlightDetails[
                                      //     index
                                      //   ].actual_airport_of_origin =
                                      //     selectedOption?.label || "";
                                      //   setActualFlightDetails(
                                      //     updatedFlightDetails
                                      //   );
                                      //   field.onChange(
                                      //     selectedOption?.label || ""
                                      //   );
                                      // }}
                                      isDisabled={true}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_origin_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Origin Airport is Required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label>
                              Departure Time
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="time"
                                className="form-control extra-light"
                                value={flight.actual_departure_time}
                                // onChange={(e) => {
                                //   const updatedFlightDetails = [
                                //     ...actualFlightDetails,
                                //   ];
                                //   const updatedTime = e.target.value;

                                //   updatedFlightDetails[
                                //     index
                                //   ].actual_departure_time = updatedTime;
                                //   setActualFlightDetails(updatedFlightDetails);
                                //   setTriggerIndex(null);
                                // }}
                                disabled
                              />
                              <i className="fa fa-clock-o designi" />
                            </div>
                            {errors[`actual_departure_time_${index}`] && (
                              <p className="errormessage">
                                Departure Time is Required
                              </p>
                            )}
                          </div>
                          {/* Airport of Destination */}
                          <div className="col-md-3">
                            <label>
                              Airport of Destination
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {flightOptions[index] &&
                            flightOptions[index].length > 0 ? (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_destination_${index}`}
                                  control={control}
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      defaultOptions={flightOptions[index].map(
                                        (option) => ({
                                          value: option.arrival.airport,
                                          arrival: option.arrival.airport,
                                          departure: option.departure.airport,
                                          label: `${option.departure.airport} - ${option.arrival.airport}`,
                                        })
                                      )}
                                      value={
                                        flight.actual_airport_of_destination
                                          ? {
                                              label:
                                                flight.actual_airport_of_destination,
                                              value:
                                                flight.actual_airport_of_destination,
                                            }
                                          : null
                                      }
                                      placeholder="Select Origin Airport"
                                      // onChange={(selectedOption) => {
                                      //   console.log(
                                      //     "selectedOptions 1466",
                                      //     selectedOption
                                      //   );
                                      //   setTriggerIndex(null);
                                      //   const updatedFlightDetails = [
                                      //     ...actualFlightDetails,
                                      //   ];

                                      //   updatedFlightDetails[
                                      //     index
                                      //   ].actual_airport_of_destination =
                                      //     selectedOption?.label || "";
                                      //   setActualFlightDetails(
                                      //     updatedFlightDetails
                                      //   );
                                      //   field.onChange(
                                      //     selectedOption?.label || ""
                                      //   );
                                      // }}
                                      isDisabled={true}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_destination_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Destination Airport is Required
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div style={{ position: "relative" }}>
                                <Controller
                                  name={`actual_airport_of_destination_${index}`}
                                  control={control}
                                  defaultValue={
                                    flight.actual_airport_of_destination
                                  }
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field }) => (
                                    <AsyncSelect
                                      {...field}
                                      cacheOptions
                                      loadOptions={loadOptions}
                                      defaultOptions={defaultOptions}
                                      value={
                                        flight.actual_airport_of_destination
                                          ? {
                                              label:
                                                flight.actual_airport_of_destination,
                                              value:
                                                flight.actual_airport_of_destination,
                                            }
                                          : null
                                      }
                                      placeholder="Select Destination Airport"
                                      // onChange={(selectedOption) => {
                                      //   setTriggerIndex(null);
                                      //   const updatedFlightDetails = [
                                      //     ...actualFlightDetails,
                                      //   ];
                                      //   updatedFlightDetails[
                                      //     index
                                      //   ].actual_airport_of_destination =
                                      //     selectedOption?.label || "";
                                      //   setActualFlightDetails(
                                      //     updatedFlightDetails
                                      //   );
                                      //   field.onChange(
                                      //     selectedOption?.label || ""
                                      //   );
                                      // }}
                                      isDisabled={true}
                                    />
                                  )}
                                />
                                {errors[
                                  `actual_airport_of_destination_${index}`
                                ] && (
                                  <p className="errormessage">
                                    Destination Airport is Required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="col-md-2">
                            <label>
                              Arrival Date
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type="date"
                                className="form-control extra-light"
                                value={flight.actual_arrival_date}
                                // onChange={(e) => {
                                //   const updatedFlightDetails = [
                                //     ...actualFlightDetails,
                                //   ];
                                //   const updatedDate = e.target.value;
                                //   updatedFlightDetails[
                                //     index
                                //   ].actual_arrival_date = updatedDate;

                                //   setActualFlightDetails(updatedFlightDetails);
                                //   setTriggerIndex(null);
                                // }}
                                disabled
                              />
                              <i className="fa fa-calendar designi" />
                            </div>
                            {errors[`actual_arrival_date_${index}`] && (
                              <p className="errormessage">
                                Arrival Date is Required
                              </p>
                            )}
                          </div>

                          <div className="col-md-2">
                            <label>
                              Arrival Time
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <div className="d-flex" style={{ gap: "20px" }}>
                                <input
                                  type="time"
                                  className="form-control extra-light"
                                  value={flight.actual_arrival_time}
                                  // onChange={(e) => {
                                  //   const updatedFlightDetails = [
                                  //     ...actualFlightDetails,
                                  //   ];
                                  //   const updatedTime = e.target.value;

                                  //   updatedFlightDetails[
                                  //     index
                                  //   ].actual_arrival_time = updatedTime;
                                  //   setActualFlightDetails(
                                  //     updatedFlightDetails
                                  //   );
                                  //   setTriggerIndex(null);
                                  // }}
                                  disabled
                                />
                                <i className="fa fa-clock-o designi" />
                                {/* {actualFlightDetails.length > 1 && (
                                  <div
                                    style={{
                                      backgroundColor: "none",
                                      color: "red",
                                      cursor: "pointer",
                                      marginLeft: "2px",
                                    }}
                                    onClick={() =>
                                      removeActualFlightDetails(index)
                                    }
                                  >
                                    <RemoveCircleOutline />
                                  </div>
                                )} */}
                              </div>
                            </div>
                            {errors[`actual_arrival_time_${index}`] && (
                              <p className="errormessage">
                                Arrival Time is Required
                              </p>
                            )}
                          </div>

                          {claimData.type_of_claim ===
                            "flight delay / flight cancellation" && (
                            <div className="col-md-6 d-flex justify-content-start align-items-center">
                              <div className="d-flex flex-wrap gap-2">
                                {/* Provided by Airline */}
                                <div>
                                  <h5 className="d-flex align-items-center">
                                    Provided by
                                  </h5>
                                </div>
                                <div className="d-flex">
                                  {" "}
                                  <div className="form-check d-flex align-items-center">
                                    <label className="form-check-label me-5 mb-0">
                                      Airline
                                    </label>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={flight.provided_by_airline}
                                      // onChange={() =>
                                      //   setActualFlightDetails((prevState) => {
                                      //     const updatedFlightDetails = [
                                      //       ...prevState,
                                      //     ];
                                      //     updatedFlightDetails[index] = {
                                      //       ...updatedFlightDetails[index],
                                      //       provided_by_airline: true,
                                      //       self_booked: false,
                                      //     };
                                      //     return updatedFlightDetails;
                                      //   })
                                      // }
                                      disabled
                                    />
                                  </div>
                                  {/* Self Booked */}
                                  <div className="form-check d-flex align-items-center">
                                    <label className="form-check-label me-5 mb-0">
                                      Self Booked
                                    </label>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={flight.self_booked}
                                      // onChange={() =>
                                      //   setActualFlightDetails((prevState) => {
                                      //     const updatedFlightDetails = [
                                      //       ...prevState,
                                      //     ];
                                      //     updatedFlightDetails[index] = {
                                      //       ...updatedFlightDetails[index],
                                      //       self_booked: true,
                                      //       provided_by_airline: false,
                                      //     };
                                      //     return updatedFlightDetails;
                                      //   })
                                      // }
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </>
                )}

                {claimData.type_of_claim ===
                  "flight delay / flight cancellation" && (
                  <>
                    {" "}
                    {/* <Button
                      color="primary"
                      onClick={addActualFlightDetails}
                      className="mt-3 mb-3"
                      style={{ width: "200px" }}
                    >
                      Additional Flight
                    </Button> */}
                    <Row>
                      <div className="col-md-4">
                        <label>
                          Delay (hours:minutes)
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ position: "relative" }}>
                          <div style={{ display: "flex", gap: "20px" }}>
                            <input
                              type="text"
                              className="form-control"
                              value={delay.split(":")[0]} // Extract hours
                              readOnly
                              style={{ width: "40%" }}
                              placeholder="HH"
                            />
                            <input
                              type="text"
                              className="form-control"
                              value={delay.split(":")[1]} // Extract minutes
                              readOnly
                              style={{ width: "40%" }}
                              placeholder="MM"
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                  </>
                )}
                <p className="tour_head_text_3">Upload Documents</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  {/* <div className="col-md-4">
                    <label>Additional Claims Documents</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        multiple
                        onChange={handleAdditionalFileChange}
                        className="form-control"
                        // style={{ width: "34%" }}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="d-flex align-items-center">
                      <label>Agreement</label>
                      {agreement && (
                        <button
                          className={`btn ml-3 ${
                            agreement ? "btn-primary" : "btn-secondary"
                          }`}
                          onClick={() => handleViewFile(agreement)}
                          disabled={!agreement}
                          style={{
                            width: "35%",
                            marginRight: "17px",
                            marginLeft: "10px",
                            marginBottom: "10px",
                            ...(agreement ? {} : { cursor: "not-allowed" }),
                          }}
                          type="button"
                        >
                          {agreement ? "View File" : "No File"}
                        </button>
                      )}
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("agreement", {
                          required: false,
                        })}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="d-flex align-items-center">
                      <label>Power of Attorney:</label>
                      {powerPdf && (
                        <button
                          className={`btn ml-3 ${
                            powerPdf ? "btn-primary" : "btn-secondary"
                          }`}
                          onClick={() => handleViewFile(powerPdf)}
                          disabled={!powerPdf}
                          style={{
                            width: "35%",
                            marginRight: "17px",
                            marginLeft: "10px",
                            marginBottom: "10px",
                            ...(powerPdf ? {} : { cursor: "not-allowed" }),
                          }}
                          type="button"
                        >
                          {powerPdf ? "View File" : "No File"}
                        </button>
                      )}
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("powerPdf", {
                          required: false,
                        })}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="d-flex align-items-center">
                      <label>POA (Airline):</label>
                      {airline_poa && (
                        <button
                          className={`btn ml-3 ${
                            airline_poa ? "btn-primary" : "btn-secondary"
                          }`}
                          onClick={() => handleViewFile(airline_poa)}
                          disabled={!airline_poa}
                          style={{
                            width: "35%",
                            marginRight: "17px",
                            marginLeft: "10px",
                            marginBottom: "10px",
                            ...(airline_poa ? {} : { cursor: "not-allowed" }),
                          }}
                          type="button"
                        >
                          {airline_poa ? "View File" : "No File"}
                        </button>
                      )}
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("airline_poa", {
                          required: false,
                        })}
                      />
                      <i className="fa fa-file-image-o designi" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <label>
                      <strong> Note to the Attorneys : </strong>Explain Your
                      Claim in Details
                    </label>
                    <div
                      className="form-control "
                      style={{
                        maxHeight: "250px",
                        overflowY: "auto",
                        marginTop: "20px",
                        padding: "0!important",
                      }}
                      id="note"
                    >
                      {previousNote && previousNote.length > 0 ? (
                        previousNote.map((note, index) => (
                          <div key={index} style={{ marginBottom: "10px" }}>
                            <div style={{ fontSize: "12px" }}>
                              {note.timestamp}
                            </div>
                            <div style={{ fontSize: "16px" }}>
                              From {note.user}: {note.note}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No notes available</div>
                      )}
                    </div>
                  </div>
                </div>

                <div style={{ padding: "0 20px", marginBottom: "50px" }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <Button
                          type="button"
                          color="success"
                          style={{
                            padding: "10px 40px",
                            marginLeft: "5px",
                            float: "right",
                            borderRadius: "40px",
                          }}
                          onClick={handlePrint}
                        >
                          Print
                        </Button>
                        <iframe ref={iframeRef} style={{ display: "none" }} />
                      </div>
                      <div>
                        <Button
                          type="button"
                          color="danger"
                          style={{
                            padding: "10px 40px",
                            marginLeft: "5px",
                            float: "right",
                            borderRadius: "40px",
                          }}
                          onClick={openMessageModal}
                        >
                          Message
                        </Button>
                        <MessageModal
                          isOpen={isMessageModalOpen}
                          onRequestClose={closeMessageModal}
                          claimId={params.claim_id}
                          previousNote={previousNote}
                          getStatusDetails={getClaimDetails}
                        />
                      </div>
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          style={{
                            padding: "10px 40px",
                            marginLeft: "5px",
                            float: "right",
                            borderRadius: "40px",
                          }}
                          disabled={!isSubmitBtnDisabled}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
          <div className="tourdetails_grid"></div>
        </div>

        {/* <FooterNew /> */}
      </React.Fragment>
      <ViewFileModal
        isOpen={imageModalOpen}
        closeModal={closeImageModal}
        modalTitle={imageModalTitle}
        modalFileUrl={imageModalFileUrl}
      />
    </div>
  );
};

export default EditClaimForm;
